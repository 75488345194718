<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="Validasi Pengajuan Survai Aspal" ok-disabled no-close-on-backdrop hide-footer>
      <b-alert variant="primary" show>
        <div class="alert-body">
          <b-row>
            <b-col md="12">
              <small>Panjang Ruas Jalan Perkerasan Aspal</small> <br>
              <h2><strong class="text-primary">{{ dataValidasi.panjang_aspal }}</strong><small>KM</small></h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" sm="3" lg="3">
              <small>Baik</small>
              <h5><strong class="text-dark">{{ dataValidasi.aspal_baik }} </strong><small></small></h5>
            </b-col>
            <b-col md="3" sm="3" lg="3">
              <small>Sedang</small>
              <h5><strong class="text-dark">{{ dataValidasi.aspal_sedang }} </strong><small></small></h5>
            </b-col>
            <b-col md="3" sm="3" lg="3">
              <small>R-Ringan</small>
              <h5><strong class="text-dark">{{ dataValidasi.aspal_rusak_ringan }} </strong><small></small></h5>
            </b-col>
            <b-col md="3" sm="3" lg="3">
              <small>R-Berat</small>
              <h5><strong class="text-dark">{{ dataValidasi.aspal_rusak_berat }} </strong><small></small></h5>
            </b-col>
          </b-row>
        </div>
      </b-alert>
      <b-row>
        <b-col md="12">
          <b-form-group label="Catatan " label-for="basicInput">
            <b-form-textarea id="textarea-default" v-model="dataValidasi.note_aspal" placeholder="Textarea" rows="3" />
            <i>** Wajib di inputkan catatn jika status direvisi</i>
          </b-form-group>
          <b-row>
            <b-col md="6" sm="6" lg="6">
              <b-button variant="warning" block @click="ActAspal(dataValidasi, 'Revisi')">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Revisi
              </b-button>
            </b-col>
            <b-col md="6" sm="6" lg="6">
              <b-button variant="success" block @click="ActAspal(dataValidasi, 'Valid')">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Validasi
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow, BFormInput, BFormGroup, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BCol, BRow, BFormInput, BFormGroup, BFormTextarea
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    dataValidasi: {}
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["ActAspal"],
  methods: {
    ActAspal(value, value2) {
      this.$emit("ActAspal", value, value2);
      this.close();
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>