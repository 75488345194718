<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel_data :rsData=rsData @validasiAspal=validasiAspal @validasiBeton=validasiBeton></tabel_data>
        </b-card>
      </b-col>
    </b-row>
    <validasi-beton :dataValidasi=dataValidasi @ActBeton=ActBeton></validasi-beton>
    <validasi-aspal :dataValidasi=dataValidasi @ActAspal=ActAspal></validasi-aspal>
  </div>
</template>

<script>
import tabel_data from './component/tabel.vue'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCol, BRow,
  BTab, BCard, BButton
} from 'bootstrap-vue'
import ValidasiAspal from './component/validasiAspal.vue'
import ValidasiBeton from './component/ValidasiBeton.vue'
export default {
  components: {
    BTabs, BCol, BRow,
    BButton,
    BTab, tabel_data, BCard,
    ValidasiAspal,
    ValidasiBeton,
  },
  data() {
    return {
      rsData: [],
      dataValidasi: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.cek_token();
    this.load_data();
  },
  methods: {

    async ActAspal(value, value2) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/survai/update_status',
        data: {
          id_kondisi: value.id_kondisi,
          note_aspal: value.note_beton,
          status_aspal: value2,
          status_beton: "0",
          note_aspal: value.note_aspal,
          note_beton: value.note_beton
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load_data();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async ActBeton(value, value2) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/survai/update_status',
        data: {
          id_kondisi: value.id_kondisi,
          note_aspal: value.note_beton,
          status_aspal: "0",
          status_beton: value2,
          note_aspal: value.note_aspal,
          note_beton: value.note_beton
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load_data();
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    validasiBeton(values) {
      this.dataValidasi = values;
    },
    validasiAspal(values) {
      console.log(values)
      this.dataValidasi = values;
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/survai/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>